import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionOuter } from '../components/section-outer'
import { SectionHead } from '../components/section-head'
import { AssetImage } from '../components/image'
import { Button } from '../components/button'
import { pageMaxWidth } from '../styles/page-max-width'
import { CroppedText } from '../components/cropped-text'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useSwiperClass } from '../hooks/useSwiperClass'
import { SvgLeftAngle, SvgRightAngle } from '../components/svg'

const Outer = styled.div`
`

const HeadContainer = styled.div`
  ${pageMaxWidth}
`

const Inner = styled.div`
  background-color: #F2F2F2;
  border-top: 1px solid #B3B3B3;
  border-bottom: 1px solid #B3B3B3;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    width: 50vw;
    height: unset;
    display: flex;
    align-items: stretch;
  }
`

const Item = styled.a`
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: clip;
  @media (max-width: 60rem){
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  & + & {
    border-left: 1px solid #B3B3B3;
    @media (max-width: 40rem){
      border-left: 0 none;
      border-top: 1px solid #B3B3B3;
    }
  }
  & > * {
    z-index: 1;
    position: relative;
  }
`

const Bg = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 1s, transform 1s;
  ${Item}:hover &{
    opacity: 1;
    transform: none;
  }
  @media (max-width: 40rem){
    display: none;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ImageContainer = styled.div`
  position: relative;
  overflow: clip;
  img {
    width: 100%;
    height: auto;
    display: block;
    transition: opacity 0.8s, transform 0.8s;
  }
  picture:nth-child(1) img {
    ${Item}:hover & {
      opacity: 0;
      @media (max-width: 40rem){
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 60rem){
    margin-top: 1rem;
  }
`

const Name = styled(CroppedText)`
  font-size: 1.125rem;
  line-height: 1.7777777777777777;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 2.2857142857142856;
  }
`

const Text1 = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  @media (max-width: 40rem){
    font-size: 0.75rem;
    line-height: 2.6666666666666665;
  }
`

const Text2 = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  margin-top: 2.5rem;
  @media (max-width: 40rem){
    font-size: 0.75rem;
    line-height: 1.75;
  }
`

const Note = styled(CroppedText)`
  margin-top: 2.5rem;
  font-size: 0.6875rem;
  line-height: 1.6363636363636365;
  max-width: calc(100% - 14rem);
  @media (max-width: 60rem){
    font-size: 0.625rem;
    line-height: 1.6;
    max-width: none;
  }
`


const ButtonContainer = styled.div`
  position: absolute;
  bottom: 6.25rem;
  right: var(--page-margin);
  @media (max-width: 60rem){
    position: static;
    align-self: flex-end;
    margin-top: 2rem;
  }
`

const Controls = styled.div`
  margin-top: 2.25rem;
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  margin-left: auto;
  margin-right: 5rem;
`

const ControlsButton = styled.div`
  padding: 1rem;
  margin: -1rem;
  cursor: pointer;
  svg {
    display: block;
    height: 0.75rem;
    width: auto;
  }
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.5;
  }
`


type TopProductsSectionProps = {

}
export const TopProductsSection: FunctionComponent<TopProductsSectionProps> = () => {
  const {ref, slideNext, slidePrev, hasNext, hasPrev} = useSwiperClass()
  return <Outer>
    <SectionOuter>
      <HeadContainer>
        <SectionHead>
          PRODUCTS
        </SectionHead>
      </HeadContainer>
      <Inner>
        <Swiper
          loop
          slidesPerView="auto"
          onSwiper={ref}
        >
          <SwiperSlide>
            <Item href="/shop/products/ZC001">
              <Bg>
                <AssetImage src="product_01_hover.png" />
              </Bg>
              <ImageContainer>
                <AssetImage src="p1-1.png" />
              </ImageContainer>
              <TitleRow>
                <Name>
                  APROS iO
                </Name>
                <Text1>
                  リンクルセラム
                </Text1>
              </TitleRow>
              <Text2>
                年齢を感じる肌のために、ヒト幹細胞培養液<sup>※1</sup>を惜しみなく配合した美容液。肌のキメを整え、うるおいやハリ、ツヤを与えます。浸透型ペプチドビタミンC誘導体<sup>※2</sup>もプラスし、明るく若々しい表情へ導きます。
              </Text2>
              <Note>
                ※1 ヒト幹細胞順化培養液（保湿成分）<br />
                ※2 構成成分：水/BG/アスコルビルメチルカルボニルペンタペプチド－７２－トリ－ｔ－ブチルトリプトファナミド（すべて保湿成分/浸透は角層まで）
              </Note>
              <ButtonContainer>
                <Button>
                  View More
                </Button>
              </ButtonContainer>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item href="/shop/products/ZZ001">
              <Bg>
                <AssetImage src="product_02_hover.png" />
              </Bg>
              <ImageContainer>
                <AssetImage src="p2-1.png" />
              </ImageContainer>
              <TitleRow>
                <Name>
                  ダニとり酵母
                </Name>
                <Text1>
                  ダニとりシート
                </Text1>
              </TitleRow>
              <Text2>
                置くだけ簡単なダニとりシート。ビール酵母で誘引し、防ダニ繊維で絡めて捕獲。キャッチしたダニをそのまま処分できます。殺虫剤や乾燥剤不使用のため、お子さまやペットのそばでも安心してご使用できます。
              </Text2>
              <ButtonContainer>
                <Button>
                  View More
                </Button>
              </ButtonContainer>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item href="/shop/products/ZH001">
              <Bg>
                <AssetImage src="p3-2.png" />
              </Bg>
              <ImageContainer>
                <AssetImage src="p3-1.png" />
              </ImageContainer>
              <TitleRow>
                <Name>
                  シボサポ
                </Name>
                <Text1>
                  サプリメント
                </Text1>
              </TitleRow>
              <Text2>
                GABA・バナバ葉由来コロソリン酸・ブラックジンジャー由来ポリメトキシフラボンを配合した機能性表示食品です。1日2粒飲むだけで手軽に続けやすく、あなたの健康習慣をサポートします。
              </Text2>
              <ButtonContainer>
                <Button>
                  View More
                </Button>
              </ButtonContainer>
            </Item>
          </SwiperSlide>
        </Swiper>
      </Inner>
      <Controls>
        <ControlsButton onClick={slidePrev}>
          <SvgLeftAngle size={12} />
        </ControlsButton>
        <ControlsButton onClick={slideNext}>
          <SvgRightAngle size={12} />
        </ControlsButton>
      </Controls>
    </SectionOuter>
  </Outer>
}